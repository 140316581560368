const isWindows = /Windows/.test(navigator.userAgent);

export const getHeightByTemplateName = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL': case '1-ICYMI-CARTOON':
    //   return isWindows ? '1098px' : '1089px';
    case '1-ICYMI-2AD-1A':
      return isWindows ? '598.8px' : '568.8px'
    case '1-ICYMI-2AD-2A':
      return '750px';
    case '1-ICYMI-POLL-NO-COL3-QR':
    case '1-ICYMI-POLL-NO-COL1-QR':
    case '1-ICYMI-POLL':
      return '1100px';
    case '1-ICYMI-CARTOON':
      return isWindows ? '1215px' : '1204.4px';
    default:
      return '1150px';
  }
};

export const getOriginalHeightByTemplateName = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL': case '1-ICYMI-CARTOON':
    //   return '1089px';
    case '1-ICYMI-2AD-1A':
      return '568.8px';
    case '1-ICYMI-2AD-2A':
      return '720px';
    case '1-ICYMI-POLL-NO-COL3-QR':
    case '1-ICYMI-POLL-NO-COL1-QR':
    case '1-ICYMI-POLL':
      return '1085.3px';
    case '1-ICYMI-CARTOON':
      return '1204.4px';
    default:
      return '1119px';
  }
};

export const getContentHeight = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case '1-ICYMI-POLL':  case '1-ICYMI-CARTOON':
    //   return 1047;
    case '1-ICYMI-2AD-1A':
      return 539.8;
    case '1-ICYMI-2AD-2A':
      return 691;
    case '1-ICYMI-POLL-NO-COL1-QR':
    case '1-ICYMI-POLL-NO-COL3-QR':
    case '1-ICYMI-POLL':
      return 1035;
    case '1-ICYMI-CARTOON':
      return 1166.4;
    default:
      return '';
  }
};

export const getContentExpand = (data) => {
  const { parent_template_name } = data;

  switch (parent_template_name) {
    // case "1-ICYMI-POLL": case "1-ICYMI-CARTOON":
    //   return 1047;
    case '1-ICYMI-2AD-1A':
      return 539.8;
      // return 686;
    case '1-ICYMI-POLL-NO-COL1-QR':
    case '1-ICYMI-POLL-NO-COL3-QR':
    case '1-ICYMI-POLL':
      return 1050;
    case '1-ICYMI-CARTOON':
      return 1166.4;
    default:
      return ''; // or return a default value if needed
  }
};

export const excludedTemplates = [
  // '1-ICYMI-2AD-1A',
  '1-ICYMI-2AD-2A',
  '1-ICYMI-CARTOON-NO-COL1-QR',
  '1-ICYMI-CARTOON-NO-COL3-QR',
];

export const templatesToUsePdfBlob = [
  '1-ICYMI-POLL-NO-COL1-QR',
  '1-ICYMI-POLL-NO-COL3-QR',
  // '1-ICYMI-2AD-1A',
  '1-ICYMI-2AD-2A',
  '1-ICYMI-CARTOON-NO-COL1-QR',
  '1-ICYMI-CARTOON-NO-COL3-QR',
];

export const templates = [
  // '1-ICYMI-POLL-NO-COL1-QR',
  // '1-ICYMI-POLL-NO-COL3-QR',
  // '1-ICYMI-2AD-1A',
  '1-ICYMI-CARTOON-NO-COL1-QR',
  '1-ICYMI-CARTOON-NO-COL3-QR',
];

export const Template_heights = {
  '1-ICYMI-POLL': '1085.3px',
  '1-ICYMI-CARTOON': '1204.4px',
  // '1-ICYMI-2AD-1A': '750px',
  '1-ICYMI-2AD-1A': '569px',
  '1-ICYMI-2AD-2A': '750px',
  '1-ICYMI-POLL-NO-COL3-QR': '1085.3px',
  '1-ICYMI-POLL-NO-COL1-QR': '1085.3px',
};
