const STAGING = "dev"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "egudzjxjlpgfyym2gnbxl4ql3chxz5217csk6qpp0p8lg7qu",
  dev: "egudzjxjlpgfyym2gnbxl4ql3chxz5217csk6qpp0p8lg7qu",
  prod: "j144dfex44adjs8i4t7i6ovy7cmu1el2n7ge9ljy5x4ufiuw",
  preview: "ykeogvnvbnk55vezguesmrrmp3f4ra1zc3dq6m8awudbr0au",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}