import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  InputLabel,
  OutlinedInput,
  Checkbox,
  Tooltip,
  IconButton,
  Toolbar,
  alpha,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

// Components
import MainCard from "ui-component/cards/MainCard";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import Popup from "components/Popup";
import PrePrintForm from "./../PrePrintForm";
import { MENU_OPEN } from "store/actions";
import { Email_List_Filter } from "store/Data";
import { Postup_Email_List } from "../Fields";
import { Postup_APIS } from "api/API";
import Axios from "api/Axios";
import "../style.css";
import RSSFeedPopup from "./RSSFeedPopup";
import ReusableMenu from "components/Menu";
import { MdContentCopy, MdOutlineDelete } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";

const validationSchema = Yup.object().shape({
  poster_Name: Yup.string().required("Poster Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const fields = [
  {
    name: "Poster_code",
    label: "Poster Code",
    options: "post_code",
    width: 2.5,
  },
  { name: "GUID", label: "GUID", options: "GUID", width: 2.5 },
  { name: "LID", label: "LID", options: "LID", width: 2 },
  {
    name: "Print_order",
    label: "Print Order",
    options: "post_order",
    width: 2,
  },
  // { name: "Poster_versions", label: "Poster Versions (V1, V2)", options: "poster_versions" },
  { name: "Package_id", label: "Package ID", options: "PACKAGE_ID", width: 2 },
  { name: "Email", label: "Email", options: "Email", width: 3, marginTop:10 },
  {
    name: "Poster_title",
    label: "Poster Title",
    options: "POSTER_TITLE",
    width: 2,
    marginTop:10
  },
];

const Preview_Email_List = ({ ...others }) => {
  const location = useLocation();
  const GetItem = localStorage.getItem("Profile_Details");
  const Details = JSON.parse(GetItem);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [openRSSFeed, setOpenRSSFeed] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getValues, setValues] = useState({ month: "", year: "", printid: "" });
  const [printData, setPrintData] = useState();
  const [FilterData, setFilterData] = useState([]);
  const [RssData, setRSSdata] = useState({ data: [], item: null });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const params = useParams();

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const Email_List_Filter_Data = useSelector(
    (state) => state.Data.PostupFilter
  );
  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      if (
        Email_List_Filter_Data &&
        Object.keys(Email_List_Filter_Data).length > 0
      ) {
        await handleRWO(newPage);
      } else {
        await Get_All_listings(newPage);
      }
    } catch (error) {
      console.log("Error in Pagination", error);
    }
  };

  const Get_All_listings = async (page) => {
    const state = location.state;

    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Postup_APIS.Preview_Email_List}?page=${page}`,
        {
          file_name: state?.file_name,
        }
      );
      if (response.status === 200) {
        // console.log(response.data.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message,
      });
      if (err.response.status === 404) {
        setShowData([]);
      }
    }
  };

  const FiltersData = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(Postup_APIS.Filter_Email_Preview);
      if (response.status === 200) {
        setIsLoading(false);
        setFilterData(response.data);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = showData.map((n) => n.id);
      // const DelteSelected = getAllWallboards.map((n) => n.wallboard_id);
      // console.log(newSelected, 'hello New Selected')
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const columns = [
    // { id: "sno", label: "#" },
    {
      id: "checkbox",
      label: Details.role !== "3" ? (
        <Checkbox
          color="primary"
          inputProps={{
            "aria-label": "select all",
          }}
          indeterminate={
            selected.length > 0 &&
            selected.length < showData.length
          }
          checked={
            showData.length > 0 &&
            selected.length === showData.length
          }
          onChange={handleSelectAllClick}
        />
      ) : (
        "#"
      ),
    },
    ...Postup_Email_List.map((item) => ({ id: item, label: item })),
    { id: "actions", label: "Actions" },
  ];

  const handleChecks = (event, id, deleteId) => {
    const selectedIndex = selected.indexOf(id);
    // const deleteIndex = deleteSelected.indexOf(deleteId);
    let newSelected = [];
    let newDeleted = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      // newDeleted = newDeleted.concat(deleteSelected, deleteId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      // newDeleted = newDeleted.concat(deleteSelected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      // newDeleted = newDeleted.concat(deleteSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      // newDeleted = newDeleted.concat(
      //   deleteSelected.slice(0, selectedIndex),
      //   deleteSelected.slice(selectedIndex + 1)
      // );
    }
    // console.log(newSelected, 'hello new Selected')
    setSelected(newSelected);
    // setDeleteSelected(newDeleted);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;


  const handleView = async (item) => {
    // setRSSdata({data:item})
    await Get_RSS_Data(item.Email, item);
  };

  const Get_RSS_Data = async (email, item) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Postup_APIS.Preview_Email, {
        email: email,
      });
      if (response.status === 200) {
        setRSSdata({ data: response.data.data, item: item });
        setIsLoading(false);
        setOpenRSSFeed(true);
      }
    } catch (error) {
      console.log(error, "Hello I am an Error");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error || error?.message,
      });
    }
  };

  const handleRWO = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Postup_APIS.Preview_Email_List}?page=${page}`,
        Email_List_Filter_Data
      );
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "Hello I am an Error");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error,
      });
    }
  };

  const Approve_Disapprove_Poster = async (id, check) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(Postup_APIS.Approve_Disapprove_Poster, {
        id: id,
        approve: check,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: response?.data?.message,
          severity: "success",
        });
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message,
      });
    }
  };

  const handleClick = async (option, item) => {
    if (option === "Email Preview") {
      handleView(item);
    }
    if (option === "Reject") {
      await Approve_Disapprove_Poster(item.id, 0);
    }
    if (option === "Approve") {
      await Approve_Disapprove_Poster(item.id, 1);
    }
  };

  const Approve_or_Disapprove =async(check)=>{
    try {
      setIsLoading(true);
      const response = await Axios.post(Postup_APIS.Multiple_Approve_Disapprove_Poster, {
        ids: selected,
        flag: check === "Approve"? 1 :0 ,
      });
      if (response.status === 200) {
        // console.log(response.data, "response");
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: response?.data?.message,
          severity: "success",
        });
        setSelected([])
      }
    } catch (err) {
      console.log(err, "Error");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: err?.response?.data?.message,
      });
    }
  }

  useEffect(() => {
    FiltersData();
    Get_All_listings(page);
    dispatch({ type: MENU_OPEN, id: "postup" });
  }, []);

  return (
    <MainCard title="Email Preview">
      <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          Email: "",
          Poster_code: "",
          GUID: "",
          LID: "",
          Print_order: "",
          Package_id: "",
          Poster_title: "",
        }}
        // validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (
              !values.Email &&
              !values.Poster_code &&
              !values.GUID &&
              !values.LID &&
              !values.Print_order &&
              !values.Package_id &&
              !values.Poster_title
            ) {
              setSnackbar({
                open: true,
                message: "Please enter at least one Input",
                severity: "error",
              });
            } else {
              setIsLoading(true);
              const data = {
                Email: values.Email || undefined,
                Poster_title: values.Poster_title || undefined,
                Poster_code: values.Poster_code || undefined,
                GUID: values.GUID || undefined,
                LID: values.LID || undefined,
                Print_order: values.Print_order || undefined,
                Package_id: values.Package_id || undefined,
              };
              dispatch(Email_List_Filter(data));
              setIsLoading(true);
              const response = await Axios.post(
                `${Postup_APIS.Preview_Email_List}?page=${page}`,
                data
              );
              if (response.status === 200) {
                // console.log(response.data, "response");
                setShowData(response.data.data);
                setNext(response?.data);
                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log(error, "Error while filter the data");
            setIsLoading(false);
            setShowData([]);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: "center", marginBottom: 5 }}
              >
                <Grid item xs={0.8}>
                  <Typography variant="subtitle1">Filter by:</Typography>
                </Grid>

                {fields.map((field) => (
                  <Grid item xs={field.width} style={{marginTop:field?.marginTop}} key={field.name}>
                    <FormControl fullWidth>
                      <Autocomplete
                        autoHighlight
                        options={FilterData[field.options] || []}
                        getOptionLabel={(option) => option || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={field.label}
                            variant="outlined"
                          />
                        )}
                        name={field.name}
                        value={
                          values[field.name]
                            ? FilterData[field.options].find(
                                (item) => item === values[field.name]
                              )
                            : ""
                        }
                        onChange={(_, newValue) => {
                          setFieldValue(field.name, newValue ? newValue : "");
                        }}
                        noOptionsText="No Results Found"
                      />
                      {touched[field.name] && errors[field.name] && (
                        <FormHelperText
                          error
                          id={`standard-weight-helper-text-${field.name}`}
                        >
                          {errors[field.name]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                ))}

                {/* Buttons */}
                <Grid item xs={3} style={{ display: "flex", gap: "10px", marginTop:6 }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                  >
                    Filter
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#C62828",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={async () => {
                      resetForm();
                      setPage(1);
                      dispatch(Email_List_Filter([]));
                      await Get_All_listings(1);
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </div>
            <Popup
              title="Please enter the details"
              open={open}
              overflowY="auto"
              // height="365px"
              content={
                <>
                  <PrePrintForm
                    setIsLoading={setIsLoading}
                    close={() => setOpen(false)}
                    value={values}
                    setSnackbar={setSnackbar}
                    printData={printData}
                  />
                </>
              }
            />
          </form>
        )}
      </Formik>

      {selected.length !== 0 && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          Approve_or_Disapprove={Approve_or_Disapprove}
        />
      )}

      <TableContainer
        className="chips"
        component={Paper}
        style={{ border: "1px solid black" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ background: "#364152", border: "none" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ textAlign: "center", color: "#fff", border: "none" }}
                  className="headings"
                  // style={{ color: "#fff" }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                const isItemSelected = isSelected(item.id);
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                      // padding="checkbox"
                    >
                      {/* {serialNumber(page, index)} */}
                      {Details.role !== "3" ? (
                          <Checkbox
                            color="primary"
                            onClick={(event) =>
                              handleChecks(
                                event,
                                item.id,
                                // item.wallboard_id
                              )
                            }
                            checked={isItemSelected}
                          />
                        ) : (
                          <>{serialNumber(page, index)}</>
                        )}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_CODE_1}
                    </TableCell>
                    <TableCell className="common">{item?.GUID}</TableCell>
                    <TableCell className="common">{item?.LID}</TableCell>
                    <TableCell className="common">{item?.post_order}</TableCell>
                    <TableCell className="common">
                      {item?.batch_number}
                    </TableCell>
                    <TableCell className="common">{item?.PACKAGE_ID}</TableCell>
                    <TableCell className="common">{item?.Email}</TableCell>
                    <TableCell className="common">{item?.FirstName}</TableCell>
                    <TableCell className="common">{item?.LastName}</TableCell>
                    <TableCell className="common">
                      {item?.POSTER_TITLE}
                    </TableCell>
                    {/* <TableCell className="common">{item?.PACKAGE_ID}</TableCell> */}
                    <TableCell className="common">{item?.file_name}</TableCell>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                    >
                      <ReusableMenu
                        APICall="no"
                        options={["Email Preview", "Approve", "Reject"]}
                        handleOption={(option) => {
                          handleClick(option, item);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={10}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        title="Please enter the details"
        open={open1}
        overflowY="auto"
        // height="365px"
        content={
          <>
            <PrePrintForm
              setIsLoading={setIsLoading}
              close={() => setOpen1(false)}
              value={getValues}
              setSnackbar={setSnackbar}
              printData={printData}
            />
          </>
        }
      />

      <Popup
        // title="RSS Feed"
        padding="0"
        open={openRSSFeed}
        // overflowY="auto"
        // height="600px"
        CloseHeight="600px"
        CloseOverflow="auto"
        width="800px"
        closePopup={() => setOpenRSSFeed(false)}
        content={
          <>
            <RSSFeedPopup
              setIsLoading={setIsLoading}
              close={() => setOpenRSSFeed(false)}
              // value={values}
              setSnackbar={setSnackbar}
              RssData={RssData}
            />
          </>
        }
      />

      {showData?.length > 0 && next?.totalCount > 10 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Preview_Email_List;


function EnhancedTableToolbar({ numSelected, Approve_or_Disapprove }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        mb: 2,
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        ""
      )}

      {numSelected > 0 ? (
        <Tooltip arrow title="Approve" placement="top">
          <IconButton onClick={() => Approve_or_Disapprove("Approve")}>
            <FaRegCircleCheck style={{color: '#00C853', fontSize:20}} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}

      {numSelected > 0 ? (
        <Tooltip title="Reject" arrow placement="top">
          <IconButton onClick={() => Approve_or_Disapprove("Disapprove")}>
            <IoCloseCircleSharp style={{color:'red',  fontSize:20}} />
          </IconButton>
        </Tooltip>
      ) : (
        ""
      )}
    </Toolbar>
  );
}
